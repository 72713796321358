<template>
    <div ref='main' class="demo-cube">
    <!-- <div class="demo-cube perspective"> -->
        <ul class="cube-inner">
          <li class="top"></li>
          <li class="bottom"></li>
          <li class="front"></li>
          <li class="back"></li>
          <li class="right"></li>
          <li class="left"></li>
        </ul>
        <ul class="cube">
          <li class="top"></li>
          <li class="bottom"></li>
          <li class="front"></li>
          <li class="back"></li>
          <li class="right"></li>
          <li class="left"></li>
        </ul>
        <p class="progress"><span v-change="2000">{{ num }}</span>%</p>
      </div>
</template>

<script>
import { find, findAll } from './utils'
    export default {
        data() {
            return {
                num: 100
            }
        },
        mounted() {
            const $main = this.$refs.main
            let tl = this.gsap.timeline()
            const $cubeInner = findAll($main, '.cube-inner li')
            const $cube = findAll($main, '.cube li')
            const $progress = find($main, '.progress')

            // let height = window.screen.height
            // gsap.to([$cubeInner, $cube])
            setTimeout(()=> {
              this.gsap.to([$cubeInner, $cube, $progress],{ opacity: 0, duration: 1, })
            }, 2000)
            
            // tl
            // .to($mask, 1, { y: -height/2 })
            // this.ScrollTrigger.create({
            //     animation: tl,
            //     trigger: $main,
            //     scrub: true,
            //     start:"top top",
            //     end:"bottom bottom",
            // })
        }
    }
</script>

<style scoped>
.demo-cube {
  margin: auto;
}

.perspective {
  transform-style: preserve-3d;
  perspective: 200px;
  transform: rotateX(10deg);
}

ul {
  padding: 0;
  list-style: none;
}
.demo-cube {
  position: relative;
  width: 100%;
  height: 200px;
    top: 40%;
    transform: translateY(-50%);

  }

  .demo-cube .cube-inner {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    transform-style: preserve-3d;
    transform-origin: 25px 25px;
    transform: rotateX(-33.5deg) rotateY(45deg);
    animation: fastspin 2s ease-in-out infinite .1s;
    }

    .demo-cube .cube-inner li {
      position: absolute;
      display: block;
      width: 50px;
      height: 50px;
      background: #961776;
      border: 1px solid #fff;
    }
    .demo-cube .cube-inner .top {
      transform: rotateX(90deg) translateZ(25px);
    }
    .demo-cube .cube-inner .bottom {
      transform: rotateX(-90deg) translateZ(25px);
    }
    .demo-cube .cube-inner .front {
      transform: translateZ(25px);
    }
    .demo-cube .cube-inner .back {
      transform: rotateX(-180deg) translateZ(25px);
    }
    .demo-cube .cube-inner .left {
      transform: rotateY(-90deg) translateZ(25px);
    }
    .demo-cube .cube-inner .right {
      transform: rotateY(90deg) translateZ(25px);
    }

    .demo-cube .cube {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    transform-style: preserve-3d;
    transform-origin: 50px 50px;
    transform: rotateX(-33.5deg) rotateY(45deg);
    animation: slowspin 2s ease-in-out infinite .1s;
        }
    .demo-cube .cube li {
      position: absolute;
      display: block;
      width: 100px;
      height: 100px;
      /* background: rgba(141, 214, 249, 0.5); */
      background: rgb(249, 141, 169, .5);
      border: 1px solid #fff;
    }
    .demo-cube .cube .top {
      transform: rotateX(90deg) translateZ(50px);
    }
    .demo-cube .cube .bottom {
      transform: rotateX(-90deg) translateZ(50px);
    }
    .demo-cube .cube .front {
      transform: translateZ(50px);
    }
    .demo-cube .cube .back {
      transform: rotateX(-180deg) translateZ(50px);
    }
    .demo-cube .cube .left {
      transform: rotateY(-90deg) translateZ(50px);
    }
    .demo-cube .cube .right {
      transform: rotateY(90deg) translateZ(50px);
    }

@keyframes fastspin {
  0% {
    transform: rotateX(-33.5deg) rotateY(45deg);
  }
  100%,
  to {
    transform: rotateX(-33.5deg) rotateY(-315deg);
  }
}

@keyframes slowspin {
  0% {
    transform: rotateX(-33.5deg) rotateY(45deg);
  }
  100%,
  to {
    transform: rotateX(-33.5deg) rotateY(315deg);
  }
}
.progress {
    font-size: 36px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50px;
    color: #ffffff;
    font-weight: bold;
}
</style>
<template>
    <div ref="main" class="main" :class="{active: active}">
        <div ref="mask" class="mask"></div>
        <div ref="loader" class="content-loader" :class="{active: active}">
            <loading-per></loading-per>
        </div>
        <div class="main-content">
            <div ref="content1" class="content content1">
                <div class="c1-mask"></div>
                <div class="item-box">
                    <h2 class="title">个人使用报告</h2>
                    <h5><span class="span1">{{ startDate }}</span><span class="span2">{{ endDate }}</span></h5>
                    <h3 class="bodycopy">
                        <span>尊敬的{{ wxUser.name }}先生/女士：</span>
                        <p>您好！点开本周阅读数据报告，又是心动的感觉！</p>
                    </h3>
                    <h4 class="">请查阅</h4>
                    <div class="arrow-down">
                    <img src="../../assets/bg/arrow-down.svg" alt="" />
                    </div>
                    <span class="text-end">融媒体中心发布</span>
                </div>
            </div>
            <div ref="content2" class="content content2">
                <div class="item-box">
                    <img class="angle-img" src="../../assets/bg/angle.png" alt="">
                    <div class="title"><span>··周期阅读详情··</span></div>
                    <p class="p1">本周期，您累计阅读文章{{ userReadInfo["count"] }}篇。其中集团内容{{ userReadInfo["count_lj"] }}篇，单位内容{{ userReadInfo["count_zd"] }}篇。</p>
                    <img class="item-img" src="../../assets/bg/gr5.png" alt="">
                    <p class="p2">累计点赞{{ userReadInfo["like"] }}篇、分享{{ userReadInfo["share"] }}篇、留言{{ userReadInfo["comment"] }}条。</p>
                    <p class="p3">累计产生平台{{ userReadInfo['integralAll'] }}积分。</p>
                </div>
            </div>
            <div ref="content3" class="content content3">
                <div class="item-box">
                    <div class="title">
                        <span>个人阅读</span>
                        <img class="angle-img" src="../../assets/bg/angle2.png" alt="">
                    </div> 
                    <div id="pie-charts" style="width: 100%; height: 40%; padding: 0 16px;"></div>
                    <div class="argument">
                        <p class="p1">阅读时长：<span>{{ secondsTomins(readTime.time) || '0' }}</span>分钟</p>
                        <p class="p2">环比增长：<span>{{ contentStr || '0%' }}</span></p>
                    </div>
                    <div id="line-charts" style="width: 100%; height: 40%; padding: 0 16px;"></div>
                </div>
            </div>
            <div ref="content4" class="content content4">
                <div class="item-box">
                    <img class="angle-img" src="../../assets/bg/angle.png" alt="">
                    <div class="title"><span>··个人积分··</span></div>
                    <div class="point">
                        <div class="history">
                            <img src="../../assets/bg/historyPoint.svg" alt="">
                            <p>历史积分<span>{{ userPoint['历史总积分'] }}</span></p>
                        </div>
                        <div class="week">
                            <img src="../../assets/bg/weekPiont.svg" alt="">
                            <p>本周积分<span>{{ userPoint['本周积分'] }}</span></p>
                        </div>
                    </div>
                    <p class="vice-title">分布详情</p>
                    <ul class="list">
                        <li><i></i>分享<span>{{ userPoint['分享总积分'] }}</span></li>
                        <li><i></i>点击<span>{{ userPoint['点击总积分'] }}</span></li>
                        <li><i></i>视频<span>{{ userPoint['视频阅读总积分'] }}</span></li>
                        <li><i></i>点赞<span>{{ userPoint['点赞总积分'] }}</span></li>
                        <li><i></i>签到<span>{{ userPoint['签到总积分'] }}</span></li>
                        <li><i></i>评论<span>{{ userPoint['评论总积分'] }}</span></li>
                        <li><i></i>阅读时长<span>{{ userPoint['阅读时长总积分'] }}</span></li>
                        <li><i></i>视频时长<span>{{ userPoint['视频阅读时长总积分'] }}</span></li>
                    </ul>
                    <div id="user-point" style="width: 100%; height: 35%; padding: 0 16px; margin-top: -34px;"></div>
                </div>
            </div>
            <div ref="content5" class="content content5">
                <div class="item-box">
                    <div class="title">
                        <span>平台活跃</span>
                        <img class="angle-img" src="../../assets/bg/angle2.png" alt="">
                    </div> 
                    <div id="week-dynamic" style="width: 100%; height: 35%; padding: 0 16px;"></div>
                    <div class="sport">
                        <img src="../../assets/bg/sport.svg" alt="">
                        <div>
                            <p>总活跃度<span>{{ activity.total }}</span></p>
                            <p>平均活跃度<span>{{ activity.average }}</span></p>
                        </div>
                        
                    </div>
                    <div class="increase">
                        <img src="../../assets/bg/increase.svg" alt="">
                        <p>环比增长<span>{{ activity.ratio || '0%' }}</span></p>
                    </div>
                    <p class="vitality-text">{{ vitalityText }}</p>
                    <p class="describe">温馨提示: 在平台参与阅读、评论、转发、收藏都可以获得活跃度哦</p>
                </div>
            </div>
            <div ref="content6" v-if="false" class="content content6">
                <div class="item-box">
                    <img class="angle-img" src="../../assets/bg/angle.png" alt="">
                    <div class="title"><span>··擂台答题··</span></div>
                    <div class="score">
                        <img src="../../assets/bg/score.svg" alt="">
                        <p>获得分数<span>{{ answer.score }}</span></p>
                    </div>
                    <div class="count">
                        <img src="../../assets/bg/count.svg" alt="">
                        <p>答题次数<span>{{ answer.count }}</span></p>
                    </div>
                    <div id="answer-charts" style="width: 100%; height: 40%; padding: 0 16px;"></div>

                    <div class="timems">
                        <img src="../../assets/bg/timems.svg" alt="">
                        <p>答题用时<span>{{ timeer.h }}时{{ timeer.m }}分{{ timeer.s }}秒</span></p>
                    </div>
                    <p class="describe">与你内心最贴近的东西，<br />切莫等闲视之。</p>
                </div>
            </div>
            <div ref="content7" class="content content7">
                <div class="item-box">
                    <div class="title">
                        <span>运动健康</span>
                        <img class="angle-img" src="../../assets/bg/angle2.png" alt="">
                    </div> 
                    <div class="runall">
                        <img src="../../assets/bg/sport2.svg" alt="">
                        <p>本周运动总步数<span>{{ runAll }}</span></p>
                    </div>
                    
                    <div id="run-charts" style="width: 100%; height: 60%; padding: 0 16px;"></div>
                    <p class="describe">健康的身体，<br />让你拥有自由和无限的可能。</p>
                </div>
            </div>
            <div ref="footer" class="content footer">
                <div class="item-box">
                    <p data-text="“企”能错过？越炫越想炫！您好棒哟！为您点赞!" class="describe">“企”能错过？越炫越想炫！您好棒哟！为您点赞!</p>
                    <img class="praise" src="../../assets/bg/praise.svg" alt="">
                    <img class="boy" src="../../assets/bg/boy.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import {
    wxConPost,
    statsPost,
    getUserPm,
    getRadio,
    getRadioDate,
    getYearOnYearBasisDate,
    getOptBar,
    formatSecond,
} from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import * as echarts from 'echarts';
import { find, findAll } from '../../components/utils'
import loadingPer from "../../components/loadingPer.vue";
    export default {
        data (){
            return {
                wxUser: '',
                active: false,
                startDate: '',
                endDate: '',
                readData:'',
                readTime: '',
                contentStr: '', // 阅读环比
                integralHisAll: '', // 总积分
                pieOption: { // 阅读平台占比
                    title: {
                        text: '阅读平台占比',
                        // subtext: 'Fake Data',
                        left: 'center',
                        textStyle: {
                            fontSize: 24
                        } 
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '15%',
                        left: 'center',
                        // doesn't perfectly work with our tricks, disable it
                        selectedMode: false
                    },
                    series: [
                        {
                            // name: 'Access From',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            center: ['50%', '70%'],
                            // adjust the start angle
                            startAngle: 180,
                            label: {
                                show: false,
                                formatter(param) {
                                // correct the percentage
                                    return param.name + ' (' + param.percent * 2 + '%)';
                                }
                            },
                            itemStyle: {
                                borderRadius: 3,
                                borderColor: '#ffeccc',
                                borderWidth: 2
                            },
                            data: [
                                { value: 1048, name: '广铁头条' },
                                { value: 735, name: '单位头条' },
                                { value: 580, name: '单位矩阵' },
                                // { value: 484, name: '' },
                                // { value: 300, name: 'xxx' },
                                {
                                    // make an record to fill the bottom 50%
                                    value: 1048 + 735 + 580,
                                    itemStyle: {
                                        // stop the chart from rendering this piece
                                        color: 'none',
                                        decal: {
                                            symbol: 'none'
                                        },
                                    },
                                    label: {
                                        show: false
                                    }
                                }
                            ]
                        }
                    ]
                },
                lineOption: { // 周阅读趋势
                    title: {
                        text: '本周阅读趋势 22～28日',
                        subtext: '数据来源：融媒体中心',
                        left: 'center',
                        textStyle: {
                            fontSize: 18
                        } 
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['22', '23', '24', '25', '26', '27', '28']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [820, 932, 901, 934, 1290, 1330, 1320],
                            type: 'line',
                            areaStyle: {
                                color: '#92cc76'
                            },
                            lineStyle: {
                                color: '#92cc76'
                            },
                            itemStyle: {
                                color: "#92cc76"
                            }
                        }
                    ]
                },
                userPoint: { // 个人积分信息
                    "分享总积分": 0, //shareIntegral
                    "点击总积分": 0, //clickIntegral
                    "视频阅读总积分": 0, //readingVideoIntegral
                    "点赞总积分": 0, // likeIntegral
                    "签到总积分": 0, //signIntegral
                    "评论总积分": 0, //commentIntegral
                    "阅读时长总积分": 0, //readingTimeIntegral
                    "视频阅读时长总积分": 0, //readingVideoTimeCountIntegral
                    "历史总积分": 0,
                    "本周积分": 0, //integralAll
                },
                userPointOption: { // 个人积分信息表
                    // tooltip: {
                    //   trigger: 'item'
                    // },
                    // legend: {
                    //     top: '5%',
                    //     left: 'left',
                    //     width: '20%',
                    //     textStyle: {
                    //         fontSize: 16
                    //     }
                    // },
                    series: [
                        {
                            // name: 'Access From',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 3,
                                borderColor: '#ffeccc',
                                borderWidth: 2
                            },
                            // top: '40%',
                            // bottom: 0,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            // emphasis: {
                            //   label: {
                            //     show: true,
                            //     fontSize: 40,
                            //     fontWeight: 'bold'
                            //   }
                            // },
                            labelLine: {
                                show: false
                            },
                            data: [
                                { value: 0, name: '分享' },
                                { value: 0, name: '点击' },
                                { value: 0, name: '视频' },
                                { value: 0, name: '点赞' },
                                { value: 0, name: '签到' },
                                { value: 0, name: '评论' },
                                { value: 0, name: '阅读时长' },
                                { value: 0, name: '视频时长' },
                            ]
                        }
                    ]
                },
                weekDynamicOption: { // 周活统计表
                    title: {
                        text: '活跃度'
                    },
                    xAxis: {
                        type: 'category',
                        data: ['22', '23', '24', '25', '26', '27', '28']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [6, 3, 8, 10, 6, 5, 3],
                            type: 'bar',
                            itemStyle: {
                                color: '#92cc76'
                            }
                        }
                    ]
                },
                activity: {
                    total: 0,
                    average: 0,
                    ratio: ''
                },
                vitalityStu: true,
                answer: { // 擂台答题
                    count: 20,
                    deptId: 2420,
                    deptName: "海口机辆轮渡段",
                    id: "1666775671523c7e1f10b",
                    paperScore: 12100,
                    score: 230,
                    timeMs: 10293
                },
                answerOption: { //周擂台答题次数
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['22', '23', '24', '25', '26', '27', '28']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [2, 3, 4, 2, 1, 5, 3],
                            type: 'line',
                            areaStyle: {
                                color: '#92cc76'
                            },
                            lineStyle: {
                                color: '#92cc76'
                            },
                            itemStyle: {
                                color: "#92cc76"
                            }
                        }
                    ]
                },
                runOption: { //微信步数
                    xAxis: {
                        type: 'category',
                        data: []
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [],
                            type: 'bar',
                            itemStyle: {
                                color: '#92cc76'
                            }
                        }
                    ]
                },
                // wxRun: 0,
                userReadInfo: {
                    name: "",
                    Positive: 0,
                    Neutral: 0,
                    Negative: 0,
                    count: 0,
                    like: 0,
                    share: 0,
                    comment: 0,
                    commentView: 0,
                    count_lj: 0,
                    count_zd: 0,
                    like_lj: 0,
                    like_zd: 0,
                    share_lj: 0,
                    share_zd: 0,
                    comment_lj: 0,
                    comment_zd: 0,
                    perCount: 0,
                    totalNum: 0,
                    ljNum: 0,
                    ljTime_s: 0,
                    zdNum: 0,
                    zdTime_s: 0,
                    jzNum: 0,
                    jzTime_s: 0,
                    signIntegral: 0,
                    clickIntegral: 0,
                    readingTimeIntegral: 0,
                    readingVideoIntegral: 0,
                    readingVideoTimeCountIntegral: 0,
                    likeIntegral: 0,
                    shareIntegral: 0,
                    commentIntegral: 0,
                    integralAll: 0,
                    wxRun: 0,
                },
                userReadOldInfo: {
                    Positive: 0,
                    Neutral: 0,
                    Negative: 0,
                    count: 0,
                    like: 0,
                    share: 0,
                    comment: 0,
                    commentView: 0,
                    count_lj: 0,
                    count_zd: 0,
                    like_lj: 0,
                    like_zd: 0,
                    share_lj: 0,
                    share_zd: 0,
                    comment_lj: 0,
                    comment_zd: 0,
                    perCount: 0,
                    totalNum: 0,
                    ljNum: 0,
                    ljTime_s: 0,
                    zdNum: 0,
                    zdTime_s: 0,
                    jzNum: 0,
                    jzTime_s: 0,
                    signIntegral: 0,
                    clickIntegral: 0,
                    readingTimeIntegral: 0,
                    readingVideoIntegral: 0,
                    readingVideoTimeCountIntegral: 0,
                    likeIntegral: 0,
                    shareIntegral: 0,
                    commentIntegral: 0,
                    integralAll: 0,
                    wxRun: 0,
                },
            }
        },
        components: {
            loadingPer
        },
        computed: {
            vitalityText () {
                return this.vitalityStu ? '相比上周更活跃了，加油！' : '学习有些懈怠了哦，抓紧迎头赶上哦，加油！'
            },
            timeer() {
            // Math.floor(endTime/1000/60/60%24)
                let h = Math.floor(this.answer.timeMs/60/60%24)
                let m = Math.floor(this.answer.timeMs/60%60)
                let s = Math.floor(this.answer.timeMs%60)
                // console.log(h);
                // console.log(m);
                // console.log(this.answer.timeMs%60);
                // console.log(s);
                var time = {
                    h: h,
                    m: m,
                    s: s
                }
                return time
            },
            runAll() {
                let all = 0
                this.runOption.series[0].data.forEach(e => {
                    all = e + all
                })
                return all
            }
        },
        async created() {
            // 取周期
            const startDate = this.getQueryString("s");
            const endDate = this.getQueryString("e");
            this.statsType = this.getQueryString("type") == "1" ? "week" : "month";
            // console.log(this.statsType);
            this.startDate = startDate
              ? moment(parseInt(startDate)).format("YYYY-MM-DD")
              : "";
            this.endDate = endDate
              ? moment(new Date(parseInt(endDate))).format("YYYY-MM-DD")
              : "";
            
            
            wxLoginApi.onReady(async () => {
                // console.log("wxLoginApi.onReady----------");
                try {
                    this.wxUser = wxLoginApi.getUser();
                    this.initData();
                    this.readData = await this.getUserRead()
                    await this.getData()
                    await this.getUserRun()
                    this.readTime = this.readDataTime()
                    this.getUserJifen()
                    // console.log('112112-----',this.readTime);
                    this.lineOptionMe()
                } catch (error) {
                    console.log(error);
                    // this.showLoad = true;
                }
            });
            
            // console.log(3333);
        },
        mounted() {
            let tl = this.gsap.timeline()
            const $main = this.$refs.main
            const $mask = this.$refs.mask
            let height = window.screen.height
            tl
            .to($mask, 1, { y: -height/2 })
            this.ScrollTrigger.create({
                animation: tl,
                trigger: $main,
                scrub: true,
                start:"top top",
                end:"bottom bottom",
            })
            // content1
            this.content1()

            // content2
            this.content2()
            
            // content3
            this.content3()
            
            // content4
            this.content4()
            
            // content5
            this.content5()
            
            // this.content6()

            this.content7()

            this.footer()
        },
        methods:{
           content1(){
            const $content1 = this.$refs.content1
            const $item = find($content1, '.item-box')
            const $title = find($content1, '.title')
            const $mask = find($content1, '.c1-mask')
            const $h3 = find($content1, 'h3')
            const $h4 = find($content1, 'h4')
            const $h5 = find($content1, 'h5')
            const $arrow = find($content1, '.arrow-down')
            const $desc = find($content1, '.text-end')
            let tl = this.gsap.timeline()
            setTimeout(()=> {
                this.active = true
                tl
                .to($item, 1, { opacity: 1 })
                .from($title, 1, { y: 150,  })
                .to($title, 1, { opacity: 1  }, '-=1')
                .from(find($h5,'.span1'), 1, { x: -50 })
                .to(find($h5,'.span1'), 1, { opacity: 1 }, '-=1')
                .from(find($h5,'.span2'), 1, { x: 50 }, '-=1')
                .to(find($h5,'.span2'), 1, { opacity: 1 }, '-=1')
                .from($h3, 1, { y: 100 })
                .to($h3, 1, { opacity: 1 }, '-=1')
                .from($h4, 1, { y: 100 })
                .to($h4, 1, { opacity: 1 }, '-=1')
                .from($arrow, 1, { y: 80 })
                .to($arrow, 1, { opacity: 1 }, '-=1')
                .to($desc, 1, { opacity: 1 })
            },2100)
            
            // this.ScrollTrigger.create({
            //     animation: tl,
            //     trigger: $content1,
            //     scrub: true,
            //     start:"top center",
            //     end:"bottom bottom",
            // })
           },
           content2() {
            const $content2 = this.$refs.content2
            const $itemBox = find($content2, '.item-box')
            const $angleImg = find($content2, '.angle-img')
            const $c2Title = find($content2, '.title')
            const $c2p1 = find($content2, '.p1')
            const $itemImg = find($content2, '.item-img')
            const $c2p2 = find($content2, '.p2')
            const $c2p3 = find($content2, '.p3')
            let tl1 = this.gsap.timeline()
            tl1
            .from($itemBox, 1, { y: 100, opacity: 0 })
            .from($angleImg, 1, { marginRight: -100, opacity: 0 })
            .from($c2Title, 1, { y: 100, opacity: 0 })
            .from($c2p1, 1, { y: 100, opacity: 0 })
            .from($itemImg, 1, { y: 80, opacity: 0 })
            .from($c2p2, 1, { y: 80, opacity: 0 })
            .from($c2p3, 1, { y: 80, opacity: 0 }, '-=.5')
            this.ScrollTrigger.create({
                animation: tl1,
                trigger: $content2,
                scrub: true,
                start:"top 70%",
                end:"bottom bottom",
            })
           },
           content3() {
            var myPieChart = echarts.init(document.getElementById('pie-charts'));
            // myPieChart.setOption();
            var myLineChart = echarts.init(document.getElementById('line-charts'));
            // myLineChart.setOption()
            const $content3 = this.$refs.content3
            this.ScrollTrigger.create({
                trigger: $content3,
                start:"top 30%",
                onEnter:()=>{
                    myPieChart.setOption(this.pieOption);
                }
            })
            this.ScrollTrigger.create({
                trigger: $content3,
                start:"top 10%",
                onEnter:()=>{
                    myLineChart.setOption(this.lineOption);
                }
            })
            const $c3ItemBox = find($content3, '.item-box')
            const $c3Title = find($content3, '.title')
            const $c3Charts1 = find($content3, '#pie-charts')
            const $c3AngleImg = find($content3, '.angle-img')
            const $c3Charts2 = find($content3, '#line-charts')
            const $c3P1 = find($content3, '.argument .p1')
            const $c3P2 = find($content3, '.argument .p2')
            
            let tl3 = this.gsap.timeline()

            tl3
            .from($c3ItemBox, 1, { y: 100, opacity: 0 })
            .from($c3Title, 1, { y: 100, opacity: 0 })
            .from($c3AngleImg, 1, { marginRight: -100, opacity: 0 })
            .from($c3Charts1, 1, { y: 100, opacity: 0 })
            .from($c3P1, 1, { y: 100, opacity: 0 })
            .from($c3P2, 1, { y: 80, opacity: 0 })
            .from($c3Charts2, 1, { y: 100, opacity: 0 })
            this.ScrollTrigger.create({
                animation: tl3,
                trigger: $content3,
                scrub: true,
                start:"top 70%",
                end:"bottom bottom",
            })
           },
           content4() {
            var userPointChart = echarts.init(document.getElementById('user-point'));
            // myLineChart.setOption()
            const $content4 = this.$refs.content4
            this.ScrollTrigger.create({
                trigger: $content4,
                start:"top 10%",
                onEnter:()=>{
                    userPointChart.setOption(this.userPointOption);
                }
            })
            // document.querySelectorAll
            const $c4ItemBox = find($content4, '.item-box')
            const $c4Title = find($content4, '.title')
            const $c4History = find($content4, '.point .history')
            const $c4Week = find($content4, '.point .week')
            const $c4ViceTitle = find($content4, '.vice-title')
            const $c4Charts = find($content4, '#user-point')
            const $c4AngleImg = find($content4, '.angle-img')
            const $list = findAll($content4, 'li')
            let tl4 = this.gsap.timeline()

            tl4
            .from($c4ItemBox, 1, { y: 100, opacity: 0 })
            .from($c4Title, 1, { y: 100, opacity: 0 })
            .from($c4AngleImg, 1, { marginRight: -100, opacity: 0 })
            .from($c4History, 1, { x: -100, opacity: 0 })
            .from($c4Week, 1, { x: 100, opacity: 0 }, '-=1')
            // .from(find($c4History, 'img'), 1, { scale: 0 })
            // .from(find($c4Week, 'img'), 1, { scale: 0 }, '-=1')
            .from(find($c4History, 'img'), 1, { opacity: 0 })
            .from(find($c4Week, 'img'), 1, { opacity: 0 }, '-=1')
            .from(find($c4History, 'span'), 1, { scale: 0 })
            .from(find($c4Week, 'span'), 1, { scale: 0 }, '-=1')
            // .from(find($c4Week, 'span'), 1, { scale: 0 }, '-=1')
            .from($c4ViceTitle, 1, { width: 0, opacity: 0 })
            .from($list[0], 1, { y: 100, opacity: 0 })
            .to($c4ViceTitle, 1, { width: '30%' }, '-=1')
            .from($list[1], 1, { y: 80, opacity: 0 }, '-=.5')
            .from($list[2], 1, { y: 80, opacity: 0 }, '-=.5')
            .from($list[3], 1, { y: 80, opacity: 0 }, '-=.5')
            .from($list[4], 1, { y: 80, opacity: 0 }, '-=.5')
            .from($list[5], 1, { y: 80, opacity: 0 }, '-=.5')
            .from($list[6], 1, { y: 80, opacity: 0 }, '-=.5')
            .from($list[7], 1, { y: 80, opacity: 0 }, '-=.5')
            .from($c4Charts, 1, { y: 100, opacity: 0 }, '-=.5')
            this.ScrollTrigger.create({
                animation: tl4,
                trigger: $content4,
                scrub: true,
                start:"top 70%",
                end:"bottom bottom",
            })
           },
           content5() {
            var weekDynamicChart = echarts.init(document.getElementById('week-dynamic'));
            // myLineChart.setOption()
            const $content5 = this.$refs.content5
            this.ScrollTrigger.create({
                trigger: $content5,
                start:"top 60%",
                onEnter:()=>{
                    weekDynamicChart.setOption(this.weekDynamicOption);
                }
            })
            const $c5ItemBox = find($content5, '.item-box')
            const $c5Title = find($content5, '.title')
            const $c5Charts = find($content5, '#week-dynamic')
            const $c5AngleImg = find($content5, '.angle-img')
            const $c5Icon1 = find($content5, '.sport img')
            const $c5Icon2 = find($content5, '.increase img')
            const $c5P1 = findAll($content5, '.sport p')[0]
            const $c5P2 = findAll($content5, '.sport p')[1]
            const $c5P3 = find($content5, '.increase p')
            const $c5P4 = find($content5, '.vitality-text')
            const $c5P5 = find($content5, '.describe')
            // console.log($c5P4);
            let tl = this.gsap.timeline()

            tl
            .from($c5ItemBox, 1, { y: 100, opacity: 0 })
            .from($c5Title, 1, { y: 100, opacity: 0 }, '-=.5')
            .from($c5Charts, 1, { y: 100, opacity: 0 }, '-=.5')
            .from($c5AngleImg, 1, { marginRight: -100, opacity: 0 }, '-=1')
            .from($c5P1, 1, { y: 40, opacity: 0 })
            .from($c5P2, 1, { y: 60, opacity: 0 }, '-=1')
            .from($c5Icon1, 1, { scale: 0 }, '-=1')
            .to($c5Icon1, 1, { scale: 1 })

            .from($c5P3, 1, { x: 150, opacity: 0 })
            .from(find($c5P3, 'span'), .2, { scale: .8 }, '-=.2')
            .from($c5Icon2, 1, { scale: 0 }, '-=1')
            .to($c5Icon2, 1, { scale: 1 })
            .from($c5P4, 1, { rotateX: '90deg', opacity: 0 })
            .to($c5P4, .3, { rotateX: '0' })
            .from($c5P5, 1, { rotateY: '90deg', y: 120, opacity: 0 })
            .to($c5P5, .3, { rotateY: '0' })
            this.ScrollTrigger.create({
                animation: tl,
                trigger: $content5,
                scrub: true,
                start:"top 70%",
                end:"bottom bottom",
            })

           },
           content6() {
            const $content6 = this.$refs.content6

            var answerChart = echarts.init(document.getElementById('answer-charts'));
            // myLineChart.setOption()
            this.ScrollTrigger.create({
                trigger: $content6,
                start:"top 60%",
                onEnter:()=>{
                    answerChart.setOption(this.answerOption);
                }
            })
            const $itemBox = find($content6, '.item-box')
            const $angleImg = find($content6, '.angle-img')
            const $c6Title = find($content6, '.title')
            const $score = find($content6, '.score')
            const $count = find($content6, '.count')
            const $timems = find($content6, '.timems')
            const $chart = find($content6, '#answer-charts')
            const $describe = find($content6, '.describe')
            let tl = this.gsap.timeline()
            tl
            .from($itemBox, 1, { y: 100, opacity: 0 })
            .from($angleImg, 1, { marginRight: -100, opacity: 0 })
            .from($c6Title, 1, { y: 100, opacity: 0 })
            .from(find($score, 'img'), 1, { scale: 0, opacity: 0 })
            .from(find($score, 'p'), 1, { x: 100, opacity: 0 }, '-=.5')
            .from(find($score, 'span'), 1, { scale: 0 }, '-=1')
            .from(find($count, 'img'), 1, { scale: 0, opacity: 0 })
            .from(find($count, 'p'), 1, { x: 100, opacity: 0 }, '-=.5')
            .from(find($count, 'span'), 1, { scale: 0 }, '-=1')
            .from($chart, 1, { y: 80, opacity: 0 })
            .from(find($timems, 'img'), 1, { scale: 0, opacity: 0 })
            .from(find($timems, 'p'), 1, { x: 100, opacity: 0 }, '-=.5')
            .from(find($timems, 'span'), 1, { scale: 0, x:10, y: -10 }, '-=1')
            .from($describe, 1, { scale: .3, rotateY: '90deg', opacity: 0 })
            .to($describe, 1, { scale: 1, rotateY: 0 })
            this.ScrollTrigger.create({
                animation: tl,
                trigger: $content6,
                scrub: true,
                start:"top 70%",
                end:"bottom bottom",
            })
           },

           content7() {
            const $content7 = this.$refs.content7
            const $span = find($content7, '.runall span')
            const runChart = echarts.init(document.getElementById('run-charts'));
            // myLineChart.setOption()
            const _that = this
            this.ScrollTrigger.create({
                trigger: $content7,
                start:"top 60%",
                onEnter:()=>{
                    runChart.setOption(this.runOption);
                }
            })
            this.ScrollTrigger.create({
                trigger: find($content7, '.runall'),
                start:"top bottom",
                onEnter:()=>{
                    _that.inserted($span, 1000)
                }
            })
            const $c7ItemBox = find($content7, '.item-box')
            const $c7Title = find($content7, '.title')
            const $runallP = find($content7, '.runall p')
            const $runallImg = find($content7, '.runall img')
            const $c7Charts = find($content7, '#run-charts')
            const $c7AngleImg = find($content7, '.angle-img')
            const $describe = find($content7, '.describe')
            let tl = this.gsap.timeline()

            tl
            .from($c7ItemBox, 1, { y: 100, opacity: 0 })
            .from($c7Title, 1, { y: 100, opacity: 0 }, '-=.5')
            .from($runallP, 1, { rotateX: '100deg', opacity: 0 }, '-=.5')
            .from($c7AngleImg, 1, { marginRight: -100, opacity: 0 }, '-=1')
            .from($runallImg, 1, { scale: 0 }, '-=1')
            .from($c7Charts, 1, { y: 100, opacity: 0 }, '-=.5')
            .from($describe, 1, { scale: .3, rotateY: '90deg', opacity: 0 })
            .to($describe, 1, { scale: 1, rotateY: 0 })
            this.ScrollTrigger.create({
                animation: tl,
                trigger: $content7,
                scrub: true,
                start:"top 70%",
                end:"bottom bottom",
            })
           },

           footer() {
            const $footer = this.$refs.footer
            const $itemBox = find($footer, '.item-box')
            const $describe = find($footer, '.describe')
            const $praise = find($footer, '.praise')
            const $boy = find($footer, '.boy')
            let tl = this.gsap.timeline()

            tl
            .from($itemBox, 1, { y: 100, opacity: 0 })
            .from($describe, 1, { y: 100, opacity: 0 }, '-=.5')
            .from($praise, 1, { y: 80, opacity: 0 }, '-=.5')
            .from($boy, 1, { scale: 0 }, '-=.5')
            // .from($c7AngleImg, 1, { marginRight: -100, opacity: 0 }, '-=1')
            // .from($runallImg, 1, { scale: 0 }, '-=1')
            // .from($c7Charts, 1, { y: 100, opacity: 0 }, '-=.5')
            // .from($describe, 1, { scale: .3, rotateY: '90deg', opacity: 0 })
            // .to($describe, 1, { scale: 1, rotateY: 0 })
            this.ScrollTrigger.create({
                animation: tl,
                trigger: $footer,
                scrub: true,
                start:"top 70%",
                end:"bottom bottom",
            })
           },

           inserted(el,binding) {
                let count = 0 //初始值
                let num = el.innerText //获取页面中的值
                let rate = 30 //定时器间隔时间
                let average = num / (binding / rate) //得到每次定时器跳动的值
            
                const timer = setInterval(() => {
                    // 将我们得到的值设置给count
                    count += average
                    el.innerText = count.toFixed(0)

                    if(count > num){
                        //为了防止数字错误将初始的值给他
                        count = num
                        el.innerText = count
                        //清除定时器
                        clearInterval(timer)
                    }
                }, rate);

            },
            // 截取字符串
            getQueryString (name) {
                const url = window.location.href;
                const r = url.match(
                    // eslint-disable-next-line
                    new RegExp(`([\?|&])${name}=([^|&||#|\/]*)(&|#|\/|$)`)
                );
                if (r !== null) {
                    const str = decodeURI(r[2]);
                    const pos = str.indexOf("#/");
                    if (pos >= 0) {
                    return str.substr(0, pos);
                    }
                    return str;
                }
                return null;
            },
            getRadio (newVal, oldVal, fixNum) {
                let _fixNum = fixNum || 2;
                if (fixNum == 0) {
                    _fixNum = 0;
                }
                let _differenceValue = 0;
                if (oldVal) {
                    _differenceValue = ((newVal - oldVal) / oldVal) * 100;
                }
                return _differenceValue;
            },
            async initData () {
                // this.initDataStatus = true;
                let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
                await this.getUserData(this.startDate, this.endDate, "curData");
                await this.getUserData(startDate, endDate, "radioData");

                // this.showUserRead = true;

                // 处理显示字符串

                // 计算文章环比
                const contentHuanBi = this.getRadio(
                    this.userReadInfo.count,
                    this.userReadOldInfo.count
                );
                this.contentStr =
                    contentHuanBi > 0
                    ? `，环比增长${Math.abs(contentHuanBi).toFixed(2)}%`
                    : "";
                this.showText =
                    contentHuanBi > 0
                    ? "请继续努力，保持良好阅读习惯哦！"
                    : "学习有些懈怠了哦，抓紧迎头赶上哦，加油！";
                console.log(this.showText);
                const jifenHuanbi = this.getRadio(
                    this.userReadInfo.integralAll,
                    this.userReadOldInfo.integralAll
                );
                this.jifenStr =
                    jifenHuanbi > 0 ? `，环比增长${Math.abs(jifenHuanbi).toFixed(2)}%` : "";
                    
            },
            async getUserData (startDate, endDate, type) {
                try {
                    // if (this.dataTimer) {
                    //     clearTimeout(this.dataTimer);
                    // }
                    // if (this.showLoad) {
                    //     return;
                    // }
                    // this.descTitle = "统计中..";
                    // this.showLoad = true;
                    let _query = {
                        startDate,
                        endDate,
                    };
                    // let _query = {
                    //   startDate: '2023-10-10',
                    //   endDate: '2023-11-23'
                    // }
                    const ret = await statsPost("/report/userReport", _query);

                    if (ret.status) {
                        // if (this.dataTimer) {
                        //     clearTimeout(this.dataTimer);
                        // }
                        this.dataTimer = setTimeout(async () => {
                            // this.showLoad = false;
                            await this.getUserData(startDate, endDate, type);
                        }, 2000);

                        return;
                    }
                    if (ret.ret) {
                        if (type == "radioData") {
                            this.userReadOldInfo = ret.ret;
                        } else {
                            this.userReadInfo = ret.ret;
                            const ljTime_s = ret.ret.ljTime_s || 0;
                            const zdTime_s = ret.ret.zdTime_s || 0;
                            const jzTime_s = ret.ret.jzTime_s || 0;
                            const allTime_s = ljTime_s + zdTime_s + jzTime_s;

                            this.userReadInfo["allTime_s"] = formatSecond(allTime_s, "minute");
                        }
                    }

                    // this.showLoad = false;
                } catch (error) {
                    console.log(error);
                    // if (this.dataTimer) {
                    //     clearTimeout(this.dataTimer);
                    // }
                }
            },
            async getUserRead () {
                if (this.dataTimer) {
                    clearTimeout(this.dataTimer);
                }
                try {
                    
                    let query = {
                        startDate:'',
                        endDate: ''
                    }
                    let _query = {
                        startDate:'',
                        endDate: ''
                    }
                    let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
                    query["startDate"] = this.startDate;
                    query["endDate"] = this.endDate;
                    _query["startDate"] = startDate;
                    _query["endDate"] = endDate;
                    const ret = await wxConPost(
                        "/services/gttt-wxstats-mgt/deptRead/getUserRead",
                        query
                    );
                    const retOld = await wxConPost(
                        "/services/gttt-wxstats-mgt/deptRead/getUserRead",
                        _query
                    );
                    if(ret.status && retOld.status) {
                        this.dataTimer = setTimeout(async ()=> {
                            await this.getUserRead()
                        }, 4000)
                    }else {
                        return [ret.ret, retOld.ret] || [];
                    }
                    
                    
                } catch (e) {
                    if (this.dataTimer) {
                        clearTimeout(this.dataTimer);
                    }
                    console.log("---ssss----", e);
                    return [];
                }
            },
            // 活跃度曲线
            async getData() {
                try {
                    let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
                    let _query = {
                        startDate: this.startDate,
                        endDate: this.endDate,
                        isUser: true
                    };
                    let query = {
                        startDate: startDate,
                        endDate: endDate,
                        isUser: true
                    };
                    const result = await wxConPost(
                        "/services/gttt-wxstats-mgt/activity/deptDailyActivity",
                        _query
                    );
                    const resultOld = await wxConPost(
                        "/services/gttt-wxstats-mgt/activity/deptDailyActivity",
                        query
                    );
                    // console.log('result',result.data);
                    let arr = []
                    let arr2 = []
                    let totalOld = 0
                    result.data.forEach(item=>{
                        arr.push(item.date_str.slice(-2))
                        arr2.push(item.count)
                    })
                    resultOld.data.forEach(item=>{
                        totalOld = totalOld + item.count
                    })

                    this.weekDynamicOption.xAxis.data = arr
                    this.weekDynamicOption.series[0].data = arr2
                    this.activity.total = _.sum(arr2)
                    this.activity.average = Math.round(_.sum(arr2) / arr2.length) || 0
                    const contentHuanBi = this.getRadio(
                        _.sum(arr2),
                        totalOld
                    );
                    // console.log(contentHuanBi);
                    this.activity.ratio =
                        contentHuanBi > 0
                        ? `${Math.abs(contentHuanBi).toFixed(2)}%`
                        : `${contentHuanBi.toFixed(2)}%`;
                    this.vitalityStu = contentHuanBi > 0 ? true : false
                    // this.activity = arr2.map(item=> {
                    //     item = item + itme
                    // })
                    // this.dataDaily = result.data;

                    // this.$nextTick(() => {
                    // this.$refs.daily.refreshData();
                    // });

                    // if (this.dataList.length > 0) {
                    //     this.showLoad = false;
                    // }
                } catch (e) {
                    console.log("----", e);
                    // this.$Notice.warning({
                    // title: "请稍后重试",
                    // });
                    // this.showLoad = false;
                }
            },
            readDataTime() {
                let obj = {
                    jzTime_s: 0, // 单位矩阵
                    ljTime_s: 0, // 广铁头条
                    zdTime_s: 0, // 单位头条
                    time: 0,
                }
                let objOld = {
                    jzTime_s: 0, // 单位矩阵
                    ljTime_s: 0, // 广铁头条
                    zdTime_s: 0, // 单位头条
                    time: 0,
                }
                this.readData[0].forEach((item)=>{
                    obj.jzTime_s = item.jzTime_s + obj.jzTime_s
                    obj.ljTime_s = item.ljTime_s + obj.ljTime_s
                    obj.zdTime_s = item.zdTime_s + obj.zdTime_s
                })
                this.readData[1].forEach((item)=>{
                    objOld.jzTime_s = item.jzTime_s + objOld.jzTime_s
                    objOld.ljTime_s = item.ljTime_s + objOld.ljTime_s
                    objOld.zdTime_s = item.zdTime_s + objOld.zdTime_s
                })
                obj.time = obj.jzTime_s + obj.ljTime_s + obj.zdTime_s
                objOld.time = objOld.jzTime_s + objOld.ljTime_s + objOld.zdTime_s
                if(obj.time) {
                    this.pieOption.series[0].data[0].value = obj.ljTime_s
                    this.pieOption.series[0].data[1].value = obj.zdTime_s
                    this.pieOption.series[0].data[2].value = obj.jzTime_s
                    this.pieOption.series[0].data[3].value = obj.time 
                }else {
                    this.pieOption.series[0].data[0].value = 1
                    this.pieOption.series[0].data[1].value = 1
                    this.pieOption.series[0].data[2].value = 1
                    this.pieOption.series[0].data[3].value = 3
                }
                // 计算阅读环比
                const contentHuanBi = this.getRadio(
                    obj.time,
                    objOld.time
                );
                this.contentStr =
                    contentHuanBi > 0
                    ? `${Math.abs(contentHuanBi).toFixed(2)}%`
                    : `${contentHuanBi.toFixed(2)}%`;
                // console.log(this.contentStr);
                return obj
            },
            // 获取积分信息
            async getUserJifen() {
                try {
                    const _query = {
                        userid: this.wxUser.userid,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    };
                    const ret = await wxConPost(
                        "/services/gttt-wxstats-mgt/integral/userIntegral",
                        _query
                    );
                    
                    ret.ret.forEach(item => {
                        this.userPoint['分享总积分'] = item.shareIntegral + this.userPoint['分享总积分']
                        this.userPoint['本周积分'] = item.integralAll + this.userPoint['本周积分']
                        this.userPoint['点击总积分'] = item.clickIntegral + this.userPoint['点击总积分']
                        this.userPoint['点赞总积分'] = item.likeIntegral + this.userPoint['点赞总积分']
                        this.userPoint['签到总积分'] = item.signIntegral + this.userPoint['签到总积分']
                        this.userPoint['视频阅读总积分'] = item.readingVideoIntegral + this.userPoint['视频阅读总积分']
                        this.userPoint['视频阅读时长总积分'] = item.readingVideoTimeCountIntegral + this.userPoint['视频阅读时长总积分']
                        this.userPoint['评论总积分'] = item.commentIntegral + this.userPoint['评论总积分']
                        this.userPoint['阅读时长总积分'] = item.readingTimeIntegral + this.userPoint['阅读时长总积分']
                    }) 
                    this.userPointOption.series[0].data[0].value = this.userPoint['分享总积分']
                    this.userPointOption.series[0].data[1].value = this.userPoint['点击总积分']
                    this.userPointOption.series[0].data[2].value = this.userPoint['视频阅读总积分']
                    this.userPointOption.series[0].data[3].value = this.userPoint['点赞总积分']
                    this.userPointOption.series[0].data[4].value = this.userPoint['签到总积分']
                    this.userPointOption.series[0].data[5].value = this.userPoint['评论总积分']
                    this.userPointOption.series[0].data[6].value = this.userPoint['阅读时长总积分']
                    this.userPointOption.series[0].data[7].value = this.userPoint['视频阅读时长总积分']
                    // 查询历史总积分
                    this.getUserJifenAll();
                } catch (e) {
                    console.log("----", e);
                }
            },
            
            async getUserJifenAll() {
                try {
                    let userIdList = [];
                    if (this.wxUser.userid) {
                        userIdList = [this.wxUser.userid];
                    }
                    const ret = await wxConPost(
                        "/services/gttt-wxstats-mgt/integral/getUserIntegralAllList",
                        { userIdList }
                    );

                    this.userPoint['历史总积分'] = _.get(ret, ["ret", "0", "integralAll"], "");
                    console.log(this.userPoint['历史总积分']);
                } catch (error) {
                    console.log("----", error);
                }
            },
            async getUserRun() {
                try {
                    // this.showLoad = true;
                    const _query = {
                        // userid: this.wxUser.userid,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    };
                    const ret = await wxConPost(
                        "/services/gttt-wxstats-mgt/activity/userWxRun",
                        _query
                    );
                    let data = _.get(ret, "data", [])
                    let arr = []
                    let arr2 = []
                    data.forEach(item => {
                        arr.push(item.wxRun)
                        arr2.push(item.date.slice(-2))
                    })
                    this.runOption.xAxis.data = arr2
                    this.runOption.series[0].data = arr
                    // this.runAll = _.sum(arr)
                    // this.dataList = _.get(ret, "data", []);
                    // console.log();
                    // this.dealData();
                    // this.showLoad = false;
                } catch (e) {
                    console.log(e);
                    // this.$Notice.warning({
                    // title: "请稍后重试",
                    // });
                    // this.showLoad = false;
                }
            },

            lineOptionMe() {
                let arr = []
                let arr2 = []
                this.readData[0].forEach((item)=> {
                    arr.push(item.date.slice(-2))
                    arr2.push(item.jzTime_s + item.ljTime_s + item.zdTime_s)
                })
                this.lineOption.title.text = `本周阅读趋势 ${this.startDate.slice(-2)}～${this.endDate.slice(-2)}日`
                this.lineOption.xAxis.data = arr
                this.lineOption.series[0].data = arr2
            },
            // 秒转化分
            secondsTomins(time) {
                return Math.floor(time/60)
            }

        }
    }
</script>

<style scoped>
    .main {
        height: 100vh;
        text-align: left;
        overflow: hidden;
    }
    .main.active {
        height: auto;
        overflow: inherit;
    }
    @keyframes bump {
        0% {
            transform: translate(-50%, 5px);
        }
        100% {
            transform: translate(-50%, -5px);
        }
    }
    /* :root {
        --base-height: 667px;
    }

    .some-element {
        height: calc(var(--base-height) * 100vh / 667);
    } */
    .arrow-down {
        /* width: 35px; */
        width: calc(35 * 100vh / 844);
        /* height: 35px; */
        height: calc(35 * 100vh / 844);
        left: 50%;
        position: relative;
        box-sizing: border-box;
        animation: bump 0.4s ease-in infinite alternate;
        opacity: 0;
    }
    .arrow-down img {
        width: 100%;
        height: 100%;
    }
    .content-loader {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
    .content-loader.active {
        z-index: initial;
    }
    
    .main-content {
        width: 100%;
        height: auto;
        position: relative;
    }
    .main-content .content {
        position: relative;
        width: 100%;
        height: 100vh;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
    }
    .content1 .c1-mask{
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../assets/bg/design-bg.jpg");
        display: none;
    }
    .content1 .item-box {
        position: relative;
        width: 100%;
        height: 90%;
        border-radius: calc(20 * 100vh / 844);
        background-color: #ffeccc;
        top: 50%;
        transform: translateY(-50%);
        padding-top: calc(30 * 100vh / 844);
        opacity: 0;
    }
    .content4 {
        overflow: hidden;
    }
    .content1 h2{
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        /* padding-top: 30px; */
        padding-top: calc(30 * 100vh / 844);
        /* margin-bottom: 30px; */
        margin-bottom: calc(30 * 100vh / 844);
        color: #ffeccc;
        opacity: 0;
        padding: calc(5 * 100vh / 844) calc(16 * 100vh / 844);
        background-color: #cc363c;
        text-align: center;
    }
    .content1 h5 {
        display: flex;
        justify-content: space-between;
        /* margin-bottom: 150px; */
        margin-bottom: calc(150 * 100vh / 844);
        color: #cc363c;
        padding: 0 calc(16 * 100vh / 844);
    }
    .content1 h5 span{
        /* font-size: 20px; */
        font-size: calc(20 * 100vh / 844);
        opacity: 0;
    }
    .content1 h4 {
        /* margin-bottom: 80px; */
        margin-bottom: calc(80 * 100vh / 844);
        /* font-size: 36px; */
        font-size: calc(36 * 100vh / 844);
        text-align: center;
        color: #cc363c;
        opacity: 0;
    }
    .content1 h3.bodycopy {
        /* margin-bottom: 80px; */
        margin-bottom: calc(80 * 100vh / 844);
        width: 100%;
        top: 40%;
        /* font-size: 24px; */
        font-size: calc(30 * 100vh / 844);
        text-align: left;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        color: #cc363c;
        opacity: 0;
    }
    .content1 h3.bodycopy p {
        color: #cc363c;
        text-align: justify;
        text-indent: 2em;
        font-size: calc(24 * 100vh / 844);
    }
    /* .content1 h3.bodycopy p i {
        margin: 0 25px;
        display: inline-block;
    } */
    .text-end {
        position: absolute;
        /* bottom: 60px; */
        bottom: calc(60 * 100vh / 844);
        /* right: 16px; */
        right: calc(16 * 100vh / 844);
        /* font-size: 16px; */
        font-size: calc(16 * 100vh / 844);
        opacity: 0;
    }
    .mask {
        width: 100%;
        height: calc(150vh + 2px);
        position: fixed;
        top: 0;
        left: 0;
        background-image: url("../../assets/bg/design-bg2.jpg");
        z-index: 0;
        margin-top: -2px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    /* .content2,.content3,.content4,.content5 {
    } */
    .content2 .item-box, .content3 .item-box, .content4 .item-box, .content5 .item-box, .content6 .item-box, .content7 .item-box, .footer .item-box {
        position: relative;
        width: 100%;
        height: 90%;
        /* border-radius: 20px; */
        border-radius: calc(20 * 100vh / 844);
        /* margin: 0 16px; */
        background-color: #ffeccc;
        top: 50%;
        transform: translateY(-50%);
    }
    .content2 .angle-img,.content4 .angle-img,.content6 .angle-img {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        right: 0;
        /* width: 144px; */
        width: calc(144 * 100vh / 844);
        height: auto;
    }
    .content2 .item-box,.content4 .item-box,.content6 .item-box {
        /* padding-top: 30px; */
        padding-top: calc(30 * 100vh / 844);
    }
    .content2 .item-box .title,.content4 .item-box .title,.content6 .item-box .title {
        position: relative;
        /* top: 30px; */
        background-color: #cc363c;
        /* padding: 10px; */
        padding: calc(10 * 100vh / 844);
        /* margin-bottom: 50px; */
        margin-bottom: calc(50 * 100vh / 844);
        text-align: center;
    }
    .content4 .item-box .title {
        /* margin-bottom: 30px; */
        margin-bottom: calc(30 * 100vh / 844);
    }
    .content2 .item-box .title span,.content4 .item-box .title span,.content6 .item-box .title span {
        color: #ffeccc;
        display: inline-block;
        /* margin: 3px; */
        margin: calc(3 * 100vh / 844);
        /* font-size: 28px; */
        font-size: calc(28 * 100vh / 844);
        /* line-height: 28px; */
        line-height: calc(28 * 100vh / 844);
        letter-spacing: .4em;
    }
    .content2 .item-box .item-img {
        display: block;
        /* width: 240px; */
        width: calc(240 * 100vh / 844);
        height: calc(240 * 100vh / 844);
        /* height: 240px; */
        /* margin: 30px auto; */
        margin: calc(30 * 100vh / 844) auto;
    }
    .content2 .item-box p {
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        text-indent: 2em;
        text-align: justify;
        /* font-size: 24px; */
        font-size: calc(24 * 100vh / 844);
        letter-spacing: .5px;
        color: #cc363c;
    }
    .content3 .angle-img,.content5 .angle-img,.content7 .angle-img {
        position: absolute;
        top: 0;
        transform: translate(25%, -100%);
        right: 0;
        /* width: 132px; */
        width: calc(132 * 100vh / 844);
        height: auto;
        z-index: 1;
    }
    .content3 .item-box,.content5 .item-box,.content7 .item-box {
        height: 85%;
        top: 52%;
    }
    .content3 .item-box .title,.content5 .item-box .title,.content7 .item-box .title {
        position: relative;
        display: inline-block;
        transform: translate(-50%, -50%);
        left: 50%;
        background-color: #cc363c;
        /* padding: 8px 60px; */
        padding: calc(8 * 100vh / 844) calc(60 * 100vh / 844);
        border-radius: 15px;
        text-align: center;
    }
    .content3 .item-box .title::after,.content5 .item-box .title::after,.content7 .item-box .title::after {
        content: ' ';
        display: block;
        width: 80%;
        height: 70%;
        position: absolute;
        transform: translate(-50%, -40%);
        background-color: #cc363c;
        /* border-radius: 35px; */
        border-radius: calc(35 * 100vh / 844);
        top: 0;
        left: 50%;
    }
    .content3 .item-box .title span,.content5 .item-box .title span,.content7 .item-box .title span {
        /* font-size: 28px; */
        font-size: calc(28 * 100vh / 844);
        color: #FFECCC;
        position: relative;
        z-index: 1;
    }
    .content3 .item-box .argument {
        /* transform: translateY(-60px); */
        /* margin-top: -50px; */
        margin-top: calc(-50 * 100vh / 844);
        /* font-size: 24px; */
        font-size: calc(24 * 100vh / 844);
        text-align: center;
        /* margin-bottom: 30px; */
        margin-bottom: calc(30 * 100vh / 844);
    }
    .content3 .item-box .argument .p1 span{
        /* font-size: 30px; */
        font-size: calc(30 * 100vh / 844);
        color: #92cc76;
        /* margin-right: 3px; */
        margin-right: calc(3 * 100vh / 844);
        font-weight: bold;
    }
    .content3 .item-box .argument .p2 span{
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        color: #ef6666;
        font-weight: bold;
    }
    .content4 .item-box .point {
        display: flex;
        width: 100%;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        justify-content: space-between;
        /* margin-bottom: 20px; */
        margin-bottom: calc(20 * 100vh / 844);
    }
    .content4 .item-box .point div {
        display: flex;
        background-color: #cc363c;
        /* padding: 12px 15px; */
        padding: calc(12 * 100vh / 844) calc(15 * 100vh / 844);
        /* border-radius: 15px; */
        border-radius: calc(15 * 100vh / 844);
        flex: 1;
        justify-content: space-between;
    }
    .content4 .item-box .point div:first-child {
        /* margin-right: 20px; */
        margin-right: calc(20 * 100vh / 844);
    }
    .content4 .item-box .point div img{
        /* width: 40px; */
        width: calc(40 * 100vh / 844);
    }
    .content4 .item-box .point div.week img {
        /* width: 35px; */
        width: calc(35 * 100vh / 844);
    }
    .content4 .item-box .point div p {
        /* font-size: 16px; */
        font-size: calc(16 * 100vh / 844);
        color: #ffeccc;
        line-height: 1;
        text-align: center;
    }
    .content4 .item-box .point div p span {
        display: block;
        /* font-size: 30px; */
        font-size: calc(30 * 100vh / 844);
        /* margin-top: 5px; */
        margin-top: calc(5 * 100vh / 844);
        font-weight: bold;
    }
    .content4 .vice-title {
        /* font-size: 18px; */
        font-size: calc(18 * 100vh / 844);
        color: #ffeccc;
        text-align: left;
        /* padding: 8px 0 6px 16px; */
        padding: calc(8 * 100vh / 844) 0 calc(6 * 100vh / 844) calc(16 * 100vh / 844);
        /* margin-bottom: 20px; */
        margin-bottom: calc(20 * 100vh / 844);
        background-color: #cc363c;
        /* border-top-right-radius: 20px; */
        border-top-right-radius: calc(20 * 100vh / 844);
        /* border-bottom-right-radius: 20px; */
        border-bottom-right-radius: calc(20 * 100vh / 844);
        width: 35%;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
    }
    .content4 .item-box .list {
        list-style: none;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
    }
    .content4 .item-box .list li {
        list-style: none;
        /* font-size: 16px; */
        font-size: calc(16 * 100vh / 844);
        /* margin-bottom: 5px; */
        margin-bottom: calc(5 * 100vh / 844);
    }
    .content4 .item-box .list li i {
        display: inline-block;
        /* width: 22px; */
        width: calc(22 * 100vh / 844);
        /* height: 12px; */
        height: calc(12 * 100vh / 844);
        /* border-radius: 2px; */
        border-radius: calc(2 * 100vh / 844);
        /* margin-right: 5px; */
        margin-right: calc(5 * 100vh / 844);
    }
    .content4 .item-box .list li span {
        /* font-size: px; */
        font-weight: bold;
        /* margin-left: 5px; */
        margin-left: calc(5 * 100vh / 844);
        line-height: 1;
    }
    .content4 .item-box .list li:nth-child(1) i {
        background-color: #5470c6;
    }
    .content4 .item-box .list li:nth-child(1) span {
        color: #5470c6;
    }
    .content4 .item-box .list li:nth-child(2) i {
        background-color: #92cc76;
    }
    .content4 .item-box .list li:nth-child(2) span {
        color: #92cc76;
    }
    .content4 .item-box .list li:nth-child(3) i {
        background-color: #fac858;
    }
    .content4 .item-box .list li:nth-child(3) span {
        color: #fac858;
    }
    .content4 .item-box .list li:nth-child(4) i {
        background-color: #ee6767;
    }
    .content4 .item-box .list li:nth-child(4) span {
        color: #ee6767;
    }
    .content4 .item-box .list li:nth-child(5) i {
        background-color: #73c0de;
    }
    .content4 .item-box .list li:nth-child(5) span {
        color: #73c0de;
    }
    .content4 .item-box .list li:nth-child(6) i {
        background-color: #3ba271;
    }
    .content4 .item-box .list li:nth-child(6) span {
        color: #3ba271;
    }
    .content4 .item-box .list li:nth-child(7) i {
        background-color: #fc8453;
    }
    .content4 .item-box .list li:nth-child(7) span {
        color: #fc8453;
    }
    .content4 .item-box .list li:nth-child(8) i {
        background-color: #9a5fb4;
    }
    .content4 .item-box .list li:nth-child(8) span {
        color: #9a5fb4;
    }
    .content7 .video-box{
        /* width: 50px; */
        width: calc(50 * 100vh / 844);
        /* height: 50px; */
        height: calc(50 * 100vh / 844);
        overflow: hidden;
        border-radius: 50%;
        position: relative;
    }
    .content7 .video-box video {
        width: 100%;
        height: 100%;
    }
    .content5 .sport,.content5 .increase {
        display: flex;
        /* font-size: 22px; */
        font-size: calc(22 * 100vh / 844);
        align-items: center;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        /* margin-bottom: 40px; */
        margin-bottom: calc(40 * 100vh / 844);
        justify-content: center;
    }
    .content5 .sport {
        justify-content: space-around;
    }
    
    .content5 .sport p,.content5 .increase p {
        line-height: 1;
    }
    .content5 .sport span,.content5 .increase span {
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        color: #3ba271;
        /* margin-left: 10px; */
        margin-left: calc(10 * 100vh / 844);
        font-weight: bold;
        display: inline-block;
        transform-origin: left 70%;
    }
    .content5 .increase span {
        color: #cc363c;
    }
    .content5 .sport img ,.content5 .increase img {
        /* width: 50px; */
        width: calc(50 * 100vh / 844);
        /* margin-right: 10px; */
        margin-right: calc(10 * 100vh / 844);
        transform: scale(1.2);
    }
    .content5 .vitality-text {
        /* font-size: 24px; */
        font-size: calc(24 * 100vh / 844);
        line-height: 1.2;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        text-indent: 2em;
        text-align: justify;
        transform: rotateX(-25deg);
    }
    .content5 .describe {
        position: absolute;
        /* bottom: 30px; */
        bottom: calc(30 * 100vh / 844);
        /* font-size: 16px; */
        font-size: calc(16 * 100vh / 844);
        color: #464646;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        transform: rotateY(-25deg);
    }
    .content6 .score img,.content6 .count img,.content6 .timems img {
        /* width: 40px; */
        width: calc(40 * 100vh / 844);
        /* margin-right: 8px; */
        margin-right: calc(8 * 100vh / 844);
    }
    .content6 .score,.content6 .count,.content6 .timems {
        display: flex;
        align-items: center;
        /* font-size: 20px; */
        font-size: calc(20 * 100vh / 844);
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        /* margin-bottom: 15px; */
        margin-bottom: calc(15 * 100vh / 844);
    }
    .content6 .score p,.content6 .count p,.content6 .timems p {
        line-height: 1;
    }
    .content6 .score {
        /* margin-bottom: 40px; */
        margin-bottom: calc(40 * 100vh / 844);
    }
    .content6 .score span,.content6 .count span,.content6 .timems span {
        /* font-size: 28px; */
        font-size: calc(28 * 100vh / 844);
        font-weight: bold;
        /* margin-left: 8px; */
        margin-left: calc(8 * 100vh / 844);
        display: inline-block;
        transform-origin: left bottom;
    }
    .content6 .describe, .content7 .describe {
        /* font-size: 18px; */
        font-size: calc(18 * 100vh / 844);
        color: #ffeccc;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        position: absolute;
        left: 50%;
        /* transform: ; */
        /* bottom: 30px; */
        bottom: calc(30 * 100vh / 844);
        background-color: #cc363c;
        width: 80%;
        text-align: center;
        /* border-radius: 5px; */
        border-radius: calc(5 * 100vh / 844);
        /* padding: 5px; */
        padding: calc(5 * 100vh / 844);
        transform: translateX(-50%) scale(1.1) ;
    }
    .main-content .content7 {
        height: 70vh;
    }
    .content7 .item-box .title {
        transform: translate(-50%, -35%);
    }
    .content7 .runall {
        /* font-size: 22px; */
        font-size: calc(22 * 100vh / 844);
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        display: flex;
        align-items: center;
    }
    .content7 .runall img {
        /* width: 50px; */
        width: calc(50 * 100vh / 844);
        /* height: 50px; */
        height: calc(50 * 100vh / 844);
    }
    .content7 .runall p {
        /* margin-left: 10px; */
        margin-left: calc(10 * 100vh / 844);
    }
    .content7 .runall span {
        /* font-size: 32px; */
        font-size: calc(32 * 100vh / 844);
        color: #cc363c;
        font-weight: bold;
        /* margin-left: 10px; */
        margin-left: calc(10 * 100vh / 844);
    }
    .content.footer {
        /* margin-bottom: 40px; */
        margin-bottom: calc(40 * 100vh / 844);
        height: 50vh;
    }
    .footer .item-box {
        /* padding: 15px 0; */
        padding: calc(15 * 100vh / 844) 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .footer .praise {
        position: relative;
        /* width: 80px; */
        width: calc(80 * 100vh / 844);
        /* height: 80px; */
        height: calc(80 * 100vh / 844);
        left: 50%;
        transform: translateX(-50%);
        animation: praise 2s infinite linear;
        transform-origin: 50% 80%;
        /* margin: 0 auto; */
    }
    @keyframes praise {
        0% {
            transform: translateX(-50%) scale(1);
        }
        50% {
            transform: translateX(-50%) scale(.5);
        }
        100% {
            transform: translateX(-50%) scale(1);
        }

    }
    .footer .boy {
        display: block;
        /* margin-top: 10px; */
        margin-top: calc(10 * 100vh / 844);
        /* width: 100px; */
        width: calc(100 * 100vh / 844);
        /* height: 100px; */
        height: calc(100 * 100vh / 844);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .footer .describe {
        position: relative;
        color: transparent;
        background-color: #cc363c;
        background-clip: text;
        /* font-size: 24px; */
        font-size: calc(24 * 100vh / 844);
        font-weight: bold;
        text-align: center;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        
    }
    .describe::after {
        content: attr(data-text);
        position: absolute;
        left: 0;
        top: 0;
        /* padding: 0 16px; */
        padding: 0 calc(16 * 100vh / 844);
        width: 100%;
        height: 100%;
        background-image: linear-gradient(120deg, transparent 0%, transparent 6rem, white 11rem, transparent 11.15rem, transparent 15rem, rgba(255, 255, 255, 0.3) 20rem, transparent 25rem, transparent 27rem, rgba(255, 255, 255, 0.6) 32rem, white 33rem, rgba(255, 255, 255, 0.3) 33.15rem, transparent 38rem, transparent 40rem, rgba(255, 255, 255, 0.3) 45rem, transparent 50rem, transparent 100%);
        background-clip: text;
        background-size: 150% 100%;
        background-repeat: no-repeat;
        animation: shine 5s infinite linear;
    }
    @keyframes shine {
        0% {
            background-position: 50% 0;
        }
        100% {
            background-position: -190% 0;
        }
    }
</style>